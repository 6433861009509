import React from 'react';
import { Users, Target, Award, Rocket } from 'lucide-react';

export default function AboutUs() {
  const values = [
    {
      icon: Target,
      title: 'Our Mission',
      description: 'To democratize AI knowledge and empower individuals and businesses with cutting-edge artificial intelligence insights.'
    },
    {
      icon: Users,
      title: 'Our Team',
      description: 'A diverse group of AI experts, researchers, and tech enthusiasts dedicated to bringing you the latest in artificial intelligence.'
    },
    {
      icon: Award,
      title: 'Our Expertise',
      description: 'Years of combined experience in AI, machine learning, and technology journalism, delivering accurate and insightful content.'
    },
    {
      icon: Rocket,
      title: 'Our Vision',
      description: 'To be the leading platform for AI news, insights, and education, helping shape the future of technology.'
    }
  ];

  return (
    <main className="flex-grow mt-14">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            About Tecowise AI
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Your trusted source for artificial intelligence news, insights, and education.
            We're dedicated to making AI accessible and understandable for everyone.
          </p>
        </div>

        {/* Values Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
          {values.map((value, index) => (
            <div key={index} className="bg-white rounded-xl shadow-md p-6">
              <div className="flex items-center mb-4">
                <div className="p-2 bg-blue-100 rounded-lg mr-4">
                  <value.icon className="w-6 h-6 text-blue-600" />
                </div>
                <h2 className="text-xl font-bold text-gray-900">{value.title}</h2>
              </div>
              <p className="text-gray-600">{value.description}</p>
            </div>
          ))}
        </div>

        {/* Story Section */}
        <div className="bg-white rounded-xl shadow-md p-8 mb-16">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Our Story</h2>
          <p className="text-gray-600 mb-4">
            Founded in 2024, Tecowise AI emerged from a simple yet powerful idea: making artificial intelligence accessible and understandable for everyone. We recognized the growing impact of AI on our daily lives and the need for reliable, clear information about these transformative technologies.
          </p>
          <p className="text-gray-600">
            Today, we continue to evolve and grow, staying at the forefront of AI developments and bringing you the most relevant news, insights, and educational content. Our commitment to quality journalism and technical accuracy remains unwavering as we help shape the future of AI education and awareness.
          </p>
        </div>

        {/* Join Us Section */}
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Join Our Journey</h2>
          <p className="text-gray-600 mb-6">
            Be part of our growing community and stay updated with the latest in AI.
          </p>
          <button className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors">
            Subscribe to Our Newsletter
          </button>
        </div>
      </div>
    </main>
  );
}