import React, { useState } from 'react';
import { MessageSquare, ThumbsUp, ThumbsDown, Flag, ChevronDown } from 'lucide-react';

interface Comment {
  id: number;
  author: string;
  content: string;
  date: string;
  likes: number;
  dislikes: number;
}

interface CommentSectionProps {
  maxInitialComments?: number;
}

export default function CommentSection({ maxInitialComments = 3 }: CommentSectionProps) {
  const [comments] = useState<Comment[]>([
    {
      id: 1,
      author: "John Doe",
      content: "This is a fantastic article! Really helped me understand the concepts better.",
      date: "2 hours ago",
      likes: 15,
      dislikes: 2
    },
    {
      id: 2,
      author: "Jane Smith",
      content: "Great insights! Looking forward to more articles like this.",
      date: "5 hours ago",
      likes: 8,
      dislikes: 1
    },
    {
      id: 3,
      author: "Mike Johnson",
      content: "The technical depth in this article is impressive. Would love to see more content like this.",
      date: "1 day ago",
      likes: 12,
      dislikes: 0
    },
    {
      id: 4,
      author: "Sarah Williams",
      content: "Very well explained! The examples really help clarify the concepts.",
      date: "2 days ago",
      likes: 10,
      dislikes: 1
    },
    {
      id: 5,
      author: "David Chen",
      content: "This article addresses some crucial points about AI development.",
      date: "3 days ago",
      likes: 9,
      dislikes: 2
    }
  ]);

  const [newComment, setNewComment] = useState('');
  const [showAllComments, setShowAllComments] = useState(false);
  
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!newComment.trim()) return;
    setNewComment('');
  };

  // Display only the specified number of comments initially
  const displayedComments = showAllComments ? comments : comments.slice(0, maxInitialComments);
  const hasMoreComments = comments.length > maxInitialComments;

  return (
    <div className="bg-white rounded-xl shadow-md p-6">
      <div className="flex items-center space-x-2 mb-6">
        <MessageSquare className="w-5 h-5 text-gray-600" />
        <h2 className="text-xl font-bold">Comments ({comments.length})</h2>
      </div>

      {/* Comment Form */}
      <form onSubmit={handleSubmit} className="mb-8">
        <textarea
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="Share your thoughts..."
          className="w-full p-4 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none"
          rows={4}
        />
        <div className="flex justify-end mt-2">
          <button
            type="submit"
            className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            Post Comment
          </button>
        </div>
      </form>

      {/* Comments List */}
      <div className="space-y-6">
        {displayedComments.map((comment) => (
          <div key={comment.id} className="border-b pb-6 last:border-b-0">
            <div className="flex justify-between items-start mb-2">
              <div>
                <h3 className="font-semibold">{comment.author}</h3>
                <span className="text-sm text-gray-500">{comment.date}</span>
              </div>
              <button className="text-gray-400 hover:text-gray-600">
                <Flag className="w-4 h-4" />
              </button>
            </div>
            <p className="text-gray-700 mb-3">{comment.content}</p>
            <div className="flex items-center space-x-4">
              <button className="flex items-center space-x-1 text-gray-500 hover:text-blue-600">
                <ThumbsUp className="w-4 h-4" />
                <span className="text-sm">{comment.likes}</span>
              </button>
              <button className="flex items-center space-x-1 text-gray-500 hover:text-red-600">
                <ThumbsDown className="w-4 h-4" />
                <span className="text-sm">{comment.dislikes}</span>
              </button>
              <button className="text-sm text-gray-500 hover:text-gray-700">Reply</button>
            </div>
          </div>
        ))}

        {/* Show More Button */}
        {hasMoreComments && (
          <button
            onClick={() => setShowAllComments(!showAllComments)}
            className="flex items-center justify-center w-full py-3 text-blue-600 hover:text-blue-700 transition-colors group"
          >
            <span className="mr-2">
              {showAllComments ? 'Show Less' : `Show More Comments (${comments.length - maxInitialComments})`}
            </span>
            <ChevronDown className={`w-4 h-4 transition-transform duration-200 ${showAllComments ? 'rotate-180' : ''}`} />
          </button>
        )}
      </div>
    </div>
  );
}