import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import ArticleManagement from './components/ArticleManagement';
import Analytics from './components/Analytics';
import AccountSettings from './components/AccountSettings';
import Sidebar from './components/Sidebar';
import Header from './components/Header';

type ActiveView = 'analytics' | 'articles' | 'settings';

export default function Dashboard() {
  const [activeView, setActiveView] = useState<ActiveView>('articles');
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  const renderContent = () => {
    switch (activeView) {
      case 'analytics':
        return <Analytics />;
      case 'articles':
        return <ArticleManagement />;
      case 'settings':
        return <AccountSettings />;
      default:
        return <ArticleManagement />;
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <Header admin={user} onLogout={handleLogout} />
      
      <div className="flex">
        <Sidebar
          activeTab={activeView}
          setActiveTab={setActiveView}
          onLogout={handleLogout}
        />
        
        <main className="flex-1 p-8">
          {renderContent()}
        </main>
      </div>
    </div>
  );
}