import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from './context/AuthContext';
import { ArticleProvider } from './context/ArticleContext';
import { Navbar } from './components/Navbar';
import Footer from './components/Footer';
import FeaturedArticle from './components/FeaturedArticle';
import ArticleGrid from './components/ArticleGrid';
import TrendingSection from './components/TrendingSection';
import AdSection from './components/AdSection';
import OfflineNotice from './components/OfflineNotice';
import SupabaseStatus from './components/SupabaseStatus';
import ErrorBoundary from './components/ErrorBoundary';
import ScrollToTop from './components/ScrollToTop';
import AiNews from './pages/AiNews';
import AiMoney from './pages/AiMoney';
import AiTools from './pages/AiTools';
import AiBlockchain from './pages/AiBlockchain';
import AiCourse from './pages/AiCourse';
import ArticlePage from './pages/ArticlePage';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import AllArticles from './pages/AllArticles';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Dashboard from './pages/admin/Dashboard';
import { useAuth } from './context/AuthContext';

const PrivateRoute = ({ children }: { children: React.ReactNode }) => {
  const { isAuthenticated, loading } = useAuth();

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return <>{children}</>;
};

const HomePage = () => {
  return (
    <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="space-y-8 mt-14">
        <ErrorBoundary>
          <FeaturedArticle />
        </ErrorBoundary>
        <AdSection />
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2">
            <ErrorBoundary>
              <ArticleGrid />
            </ErrorBoundary>
          </div>
          <div className="lg:col-span-1">
            <ErrorBoundary>
              <TrendingSection />
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </div>
  );
};

const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <ErrorBoundary>
        <Navbar />
        <SupabaseStatus />
        <div className="flex-grow">
          {children}
        </div>
        <Footer />
        <OfflineNotice />
      </ErrorBoundary>
    </div>
  );
};

export default function App() {
  return (
    <ErrorBoundary>
      <HelmetProvider>
        <AuthProvider>
          <ArticleProvider>
            <Router>
              <ScrollToTop />
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/admin/dashboard" element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                } />
                <Route path="/" element={
                  <Layout>
                    <HomePage />
                  </Layout>
                } />
                <Route path="/articles" element={
                  <Layout>
                    <AllArticles />
                  </Layout>
                } />
                <Route path="/ai-news" element={
                  <Layout>
                    <AiNews />
                  </Layout>
                } />
                <Route path="/ai-money" element={
                  <Layout>
                    <AiMoney />
                  </Layout>
                } />
                <Route path="/ai-tools" element={
                  <Layout>
                    <AiTools />
                  </Layout>
                } />
                <Route path="/ai-blockchain" element={
                  <Layout>
                    <AiBlockchain />
                  </Layout>
                } />
                <Route path="/ai-course" element={
                  <Layout>
                    <AiCourse />
                  </Layout>
                } />
                <Route path="/article/:slug" element={
                  <Layout>
                    <ArticlePage />
                  </Layout>
                } />
                <Route path="/about" element={
                  <Layout>
                    <AboutUs />
                  </Layout>
                } />
                <Route path="/contact" element={
                  <Layout>
                    <Contact />
                  </Layout>
                } />
                <Route path="/privacy" element={
                  <Layout>
                    <Privacy />
                  </Layout>
                } />
                <Route path="/terms" element={
                  <Layout>
                    <Terms />
                  </Layout>
                } />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </Router>
          </ArticleProvider>
        </AuthProvider>
      </HelmetProvider>
    </ErrorBoundary>
  );
}