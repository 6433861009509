import React from 'react';
import { Link } from 'react-router-dom';

interface Article {
  title: string;
  description: string;
  image: string;
  date: string;
  readTime: string;
  url: string;
}

interface ArticleLayoutProps {
  category: string;
  articles: Article[];
}

export default function ArticleLayout({ category, articles }: ArticleLayoutProps) {
  return (
    <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 mt-14">
      <h1 className="text-4xl font-bold mb-8">{category}</h1>
      {articles.length === 0 ? (
        <div className="text-center py-12">
          <p className="text-gray-600">No articles found in this category.</p>
        </div>
      ) : (
        <div className="grid grid-cols-1 gap-8">
          {articles.map((article, index) => (
            <Link 
              key={index} 
              to={article.url}
              className="bg-white rounded-xl overflow-hidden shadow-md hover:shadow-lg transition-shadow flex flex-col md:flex-row"
            >
              <div className="md:w-1/3">
                <img
                  src={article.image}
                  alt={article.title}
                  className="w-full h-64 md:h-full object-cover"
                />
              </div>
              <div className="md:w-2/3 p-6">
                <div className="flex items-center text-sm text-gray-600 mb-2">
                  <span>{article.date}</span>
                  <span className="mx-2">•</span>
                  <span>{article.readTime} read</span>
                </div>
                <h2 className="text-2xl font-bold mb-3">{article.title}</h2>
                <p className="text-gray-600 mb-4">{article.description}</p>
                <span className="text-blue-600 hover:text-blue-700">Read more →</span>
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}