import React from 'react';
import { FileText, Shield, AlertTriangle, Scale } from 'lucide-react';

export default function Terms() {
  const sections = [
    {
      icon: FileText,
      title: 'Terms of Use',
      content: `By accessing and using this website, you accept and agree to be bound by the terms and provision of this agreement. If you do not agree to abide by the above, please do not use this service.`
    },
    {
      icon: Shield,
      title: 'Intellectual Property',
      content: `All content included on this site, such as text, graphics, logos, images, audio clips, digital downloads, and data compilations, is the property of Tecowise AI or its content suppliers and protected by international copyright laws.`
    },
    {
      icon: AlertTriangle,
      title: 'Limitations of Liability',
      content: `Tecowise AI shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages resulting from the use or inability to use the service or for the cost of procurement of substitute goods and services.`
    },
    {
      icon: Scale,
      title: 'Governing Law',
      content: `These terms and conditions are governed by and construed in accordance with the laws of the United States and you irrevocably submit to the exclusive jurisdiction of the courts in that location.`
    }
  ];

  return (
    <main className="flex-grow mt-14">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">Terms of Service</h1>
          <p className="text-gray-600">
            Last updated: March 15, 2024
          </p>
        </div>

        <div className="bg-white rounded-xl shadow-md p-8 mb-8">
          <p className="text-gray-600">
            Welcome to Tecowise AI. By accessing our website and using our services, you agree to comply with and be bound by the following terms and conditions of use.
          </p>
        </div>

        <div className="space-y-8">
          {sections.map((section, index) => (
            <div key={index} className="bg-white rounded-xl shadow-md p-8">
              <div className="flex items-center mb-4">
                <div className="p-2 bg-blue-100 rounded-lg mr-4">
                  <section.icon className="w-6 h-6 text-blue-600" />
                </div>
                <h2 className="text-2xl font-bold text-gray-900">{section.title}</h2>
              </div>
              <p className="text-gray-600">{section.content}</p>
            </div>
          ))}
        </div>

        <div className="bg-white rounded-xl shadow-md p-8 mt-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Changes to Terms</h2>
          <p className="text-gray-600 mb-4">
            We reserve the right to modify these terms at any time. We do so by posting and drawing attention to the updated terms on the Site. Your decision to continue to visit and make use of the Site after such changes have been made constitutes your formal acceptance of the new Terms of Service.
          </p>
          <p className="text-gray-600">
            If you have any questions about these Terms, please contact us at legal@tecowise.ai
          </p>
        </div>
      </div>
    </main>
  );
}