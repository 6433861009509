import React from 'react';
import { Link } from 'react-router-dom';
import { useArticles } from '../context/ArticleContext';
import { Loader2 } from 'lucide-react';
import { generateSlug } from '../utils/slugUtils';

export default function FeaturedArticle() {
  const { articles, isLoading, error } = useArticles();

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader2 className="w-8 h-8 animate-spin text-blue-600" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-600 text-center p-4">
        Error loading featured article: {error}
      </div>
    );
  }

  const featuredArticle = articles.find(article => article.category === "AI News");
  if (!featuredArticle) return null;

  const slug = generateSlug(featuredArticle.title);

  return (
    <Link 
      to={`/article/${slug}`}
      className="relative h-[500px] rounded-xl overflow-hidden group block"
    >
      {featuredArticle.images?.[0] && (
        <img
          src={featuredArticle.images[0]}
          alt={featuredArticle.title}
          className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
        />
      )}
      <div className="absolute inset-0 bg-gradient-to-t from-black/80 to-transparent">
        <div className="absolute bottom-0 p-8">
          <span className="text-blue-400 text-sm font-semibold">FEATURED</span>
          <h2 className="text-4xl font-bold text-white mt-2 mb-4 group-hover:text-blue-400 transition-colors">
            {featuredArticle.title}
          </h2>
          <p className="text-gray-200 text-lg max-w-2xl mb-4">
            {featuredArticle.description}
          </p>
          <div className="flex items-center text-gray-300 text-sm">
            <span>{new Date(featuredArticle.created_at).toLocaleDateString()}</span>
            <span className="mx-2">•</span>
            <span>{featuredArticle.read_time} read</span>
          </div>
        </div>
      </div>
    </Link>
  );
}