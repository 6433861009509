import React from 'react';
import { BarChart2 } from 'lucide-react';

export default function Analytics() {
  return (
    <div className="flex flex-col items-center justify-center h-64 bg-white rounded-xl shadow-sm p-6">
      <BarChart2 className="w-12 h-12 text-gray-400 mb-4" />
      <h2 className="text-xl font-semibold text-gray-900 mb-2">Analytics</h2>
      <p className="text-gray-600 text-center">
        Analytics are now handled through Google Analytics. Please check your Google Analytics dashboard for detailed metrics and insights.
      </p>
    </div>
  );
}