import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://tcsvfhdhzxnwbohwhueq.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InRjc3ZmaGRoenhud2JvaHdodWVxIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzA1MzkzODYsImV4cCI6MjA0NjExNTM4Nn0.OavAwR8-T0mXZnNMnrbx8dDdgzz8B6A9AKR7y2WrcvY';

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase configuration');
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    persistSession: true,
    autoRefreshToken: true,
    detectSessionInUrl: true
  },
  db: {
    schema: 'public'
  },
  global: {
    headers: {
      'x-application-name': 'tecowise-ai'
    }
  }
});