import React from 'react';
import ArticleLayout from '../components/ArticleLayout';
import AdSection from '../components/AdSection';
import { useArticles } from '../context/ArticleContext';
import { Loader2 } from 'lucide-react';

export default function AiCourse() {
  const { articles, isLoading, error } = useArticles();

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader2 className="w-8 h-8 animate-spin text-blue-600" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-600 text-center p-4">
        Error loading articles: {error}
      </div>
    );
  }

  const categoryArticles = articles
    .filter(article => article.category === "AI Course")
    .map(article => ({
      title: article.title,
      description: article.description,
      image: article.images[0],
      date: article.date,
      readTime: article.readTime,
      url: `/article/${article.title
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '-')
        .replace(/(^-|-$)/g, '')}`
    }));

  return (
    <div className="flex-grow mt-14">
      <div className="max-w-7xl mx-auto px-4">
        <ArticleLayout category="AI Course" articles={categoryArticles} />
        <AdSection />
      </div>
    </div>
  );
}