import { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import type { Article } from '../types';

export function useArticle(slug: string | undefined) {
  const [article, setArticle] = useState<Article | null>(null);
  const [relatedArticles, setRelatedArticles] = useState<Article[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchArticle = async () => {
      if (!slug) {
        setError('No slug provided');
        setLoading(false);
        return;
      }

      try {
        const { data: articles, error: fetchError } = await supabase
          .from('articles')
          .select('*')
          .order('created_at', { ascending: false });

        if (fetchError) throw fetchError;

        // Find the article by converting its title to a slug and comparing
        const foundArticle = articles?.find(article => 
          article.title.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/^-+|-+$/g, '') === slug
        );

        if (!foundArticle) {
          throw new Error('Article not found');
        }

        const articleData: Article = {
          id: foundArticle.id,
          title: foundArticle.title,
          author: foundArticle.author,
          category: foundArticle.category,
          description: foundArticle.description || '',
          content: foundArticle.content,
          read_time: foundArticle.read_time || '5 min',
          images: foundArticle.images || [],
          created_at: foundArticle.created_at,
          updated_at: foundArticle.updated_at
        };
        setArticle(articleData);

        // Get related articles from the same category
        const related = articles
          .filter(a => a.category === foundArticle.category && a.id !== foundArticle.id)
          .slice(0, 3)
          .map(article => ({
            id: article.id,
            title: article.title,
            author: article.author,
            category: article.category,
            description: article.description || '',
            content: article.content,
            read_time: article.read_time || '5 min',
            images: article.images || [],
            created_at: article.created_at,
            updated_at: article.updated_at
          }));

        setRelatedArticles(related);
      } catch (err) {
        console.error('Error fetching article:', err);
        setError(err instanceof Error ? err.message : 'Failed to fetch article');
        setArticle(null);
      } finally {
        setLoading(false);
      }
    };

    setLoading(true);
    setError(null);
    fetchArticle();
  }, [slug]);

  return { article, relatedArticles, loading, error };
}