import React from 'react';
import { Link } from 'react-router-dom';
import { useArticles } from '../context/ArticleContext';
import { generateSlug } from '../utils/slugUtils';

export default function TrendingSection() {
  const { articles, isLoading } = useArticles();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  // Get the 5 most recent articles across all categories
  const trending = articles
    .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
    .slice(0, 5)
    .map(article => ({
      title: article.title,
      slug: generateSlug(article.title)
    }));

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">Trending Now</h2>
      <div className="space-y-4">
        {trending.map((item, index) => (
          <Link 
            key={index}
            to={`/article/${item.slug}`}
            className="flex items-center space-x-3 group"
          >
            <span className="text-blue-600 font-bold">{index + 1}</span>
            <p className="text-gray-800 group-hover:text-blue-600 transition-colors">{item.title}</p>
          </Link>
        ))}
      </div>
    </div>
  );
}