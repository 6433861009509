import React from 'react';
import { Shield, Lock, Eye, UserCheck } from 'lucide-react';

export default function Privacy() {
  const sections = [
    {
      icon: Shield,
      title: 'Information We Collect',
      content: `We collect information that you provide directly to us, including when you create an account, subscribe to our newsletter, or contact us. This may include:
        • Name and email address
        • Account credentials
        • Communication preferences
        • Any other information you choose to provide`
    },
    {
      icon: Lock,
      title: 'How We Use Your Information',
      content: `We use the information we collect to:
        • Provide and maintain our services
        • Send you updates and marketing communications
        • Respond to your comments and questions
        • Protect against fraudulent or illegal activity`
    },
    {
      icon: Eye,
      title: 'Information Sharing',
      content: `We do not sell or rent your personal information to third parties. We may share your information with:
        • Service providers who assist in our operations
        • Law enforcement when required by law
        • Other parties with your consent`
    },
    {
      icon: UserCheck,
      title: 'Your Rights and Choices',
      content: `You have certain rights regarding your personal information:
        • Access your personal information
        • Correct inaccurate information
        • Request deletion of your information
        • Opt-out of marketing communications`
    }
  ];

  return (
    <main className="flex-grow mt-14">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">Privacy Policy</h1>
          <p className="text-gray-600">
            Last updated: March 15, 2024
          </p>
        </div>

        <div className="bg-white rounded-xl shadow-md p-8 mb-8">
          <p className="text-gray-600 mb-6">
            At Tecowise AI, we take your privacy seriously. This Privacy Policy explains how we collect, use, and protect your personal information when you use our website and services.
          </p>
        </div>

        <div className="space-y-8">
          {sections.map((section, index) => (
            <div key={index} className="bg-white rounded-xl shadow-md p-8">
              <div className="flex items-center mb-4">
                <div className="p-2 bg-blue-100 rounded-lg mr-4">
                  <section.icon className="w-6 h-6 text-blue-600" />
                </div>
                <h2 className="text-2xl font-bold text-gray-900">{section.title}</h2>
              </div>
              <div className="text-gray-600 whitespace-pre-line">
                {section.content}
              </div>
            </div>
          ))}
        </div>

        <div className="bg-white rounded-xl shadow-md p-8 mt-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Contact Us</h2>
          <p className="text-gray-600 mb-4">
            If you have any questions about this Privacy Policy, please contact us at:
          </p>
          <ul className="text-gray-600">
            <li>Email: contact@tecowise.com</li>
            <li>Phone: +1 (555) 123-4567</li>
            <li>Address: 123 AI Street, Tech Valley, CA 94025, United States</li>
          </ul>
        </div>
      </div>
    </main>
  );
}