import React from 'react';
import { useParams, Link, Navigate } from 'react-router-dom';
import { ArrowLeft, Loader2, AlertCircle } from 'lucide-react';
import { useArticle } from '../hooks/useArticle';
import ArticleSEO from '../components/ArticleSEO';
import AdSection from '../components/AdSection';
import CommentSection from '../components/CommentSection';
import { generateSlug } from '../utils/slugUtils';
import { sanitizeHtml } from '../utils/sanitizeHtml';

export default function ArticlePage() {
  const { slug } = useParams<{ slug: string }>();
  const { article, relatedArticles, loading, error } = useArticle(slug);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="w-8 h-8 animate-spin text-blue-600" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen px-4">
        <AlertCircle className="w-12 h-12 text-red-500 mb-4" />
        <h1 className="text-2xl font-bold text-gray-900 mb-2">Article Not Found</h1>
        <p className="text-gray-600 mb-6 text-center">{error}</p>
        <Link 
          to="/"
          className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Return to Home
        </Link>
      </div>
    );
  }

  if (!article) {
    return <Navigate to="/404" replace />;
  }

  const canonicalSlug = generateSlug(article.title);
  if (slug !== canonicalSlug) {
    return <Navigate to={`/article/${canonicalSlug}`} replace />;
  }

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const sanitizedContent = sanitizeHtml(article.content);

  return (
    <>
      <ArticleSEO article={article} />
      <main className="flex-grow mt-14">
        <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <Link 
            to="/" 
            className="inline-flex items-center text-gray-600 hover:text-gray-900 mb-8 group transition-colors"
          >
            <ArrowLeft className="w-5 h-5 mr-2 group-hover:-translate-x-1 transition-transform" />
            Back to Home
          </Link>

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            <div className="lg:col-span-2 space-y-8">
              <article className="bg-white rounded-xl shadow-md overflow-hidden">
                {article.images?.[0] && (
                  <div className="relative h-96">
                    <img
                      src={article.images[0]}
                      alt={article.title}
                      className="w-full h-full object-cover"
                    />
                  </div>
                )}
                <div className="p-8">
                  <div className="mb-4">
                    <span className="text-blue-600 font-medium">{article.category}</span>
                  </div>
                  <h1 className="text-4xl font-bold mb-4">{article.title}</h1>
                  <div className="flex items-center text-gray-600 mb-6">
                    <span>{typeof article.author === 'string' ? article.author : article.author.name}</span>
                    <span className="mx-2">•</span>
                    <span>{formatDate(article.created_at)}</span>
                    <span className="mx-2">•</span>
                    <span>{article.read_time} read</span>
                  </div>
                  <div 
                    className="prose max-w-none"
                    dangerouslySetInnerHTML={{ __html: sanitizedContent }}
                  />
                </div>
              </article>

              <CommentSection articleId={article.id} />
              <AdSection />
            </div>

            <div className="lg:col-span-1">
              <div className="bg-white rounded-xl shadow-md p-6">
                <h2 className="text-xl font-bold mb-4">Related Articles</h2>
                <div className="space-y-6">
                  {relatedArticles.map((related) => (
                    <Link 
                      key={related.id}
                      to={`/article/${generateSlug(related.title)}`}
                      className="group block"
                    >
                      {related.images?.[0] && (
                        <div className="mb-2">
                          <img
                            src={related.images[0]}
                            alt={related.title}
                            className="w-full h-32 object-cover rounded-lg"
                          />
                        </div>
                      )}
                      <h3 className="font-semibold group-hover:text-blue-600 transition-colors">
                        {related.title}
                      </h3>
                      <p className="text-sm text-gray-600 mt-1">{related.read_time} read</p>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}