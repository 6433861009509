import React, { useState, useEffect } from 'react';
import { CheckCircle, XCircle } from 'lucide-react';
import { supabase } from '../lib/supabase';

export default function SupabaseStatus() {
  const [isConnected, setIsConnected] = useState(false);
  const [showStatus, setShowStatus] = useState(true);

  useEffect(() => {
    const checkConnection = async () => {
      try {
        const { data, error } = await supabase.from('articles').select('count');
        if (!error) {
          setIsConnected(true);
        }
      } catch (error) {
        setIsConnected(false);
      }
    };

    checkConnection();

    // Hide the status message after 5 seconds
    const timer = setTimeout(() => {
      setShowStatus(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  if (!showStatus) return null;

  return (
    <div className={`fixed top-20 right-4 p-4 rounded-lg shadow-lg transition-all duration-500 ${
      isConnected ? 'bg-green-50' : 'bg-red-50'
    }`}>
      <div className="flex items-center space-x-2">
        {isConnected ? (
          <>
            <CheckCircle className="h-5 w-5 text-green-500" />
            <span className="text-green-700">Welcome to Tecowise AI!</span>
          </>
        ) : (
          <>
            <XCircle className="h-5 w-5 text-red-500" />
            <span className="text-red-700">Failed to connect to Supabase</span>
          </>
        )}
      </div>
    </div>
  );
}