import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';
import { useArticles } from '../context/ArticleContext';
import { generateSlug } from '../utils/slugUtils';

const categories = ["AI News", "AI Money", "AI Tools", "AI Blockchain", "AI Course"];

export default function ArticleGrid() {
  const { articles, isLoading } = useArticles();
  const [articlesByCategory, setArticlesByCategory] = useState<{ category: string; articles: any[] }[]>([]);

  useEffect(() => {
    const categorizedArticles = categories.map(category => {
      const categoryArticles = articles
        .filter(article => article.category === category)
        .slice(0, 2)
        .map(article => ({
          ...article,
          slug: generateSlug(article.title)
        }));
      return { category, articles: categoryArticles };
    });
    setArticlesByCategory(categorizedArticles);
  }, [articles]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="space-y-12">
      {articlesByCategory.map(({ category, articles }) => (
        <div key={category} className="space-y-6">
          <div className="flex items-center justify-between">
            <h2 className="text-2xl font-bold text-gray-900">{category}</h2>
            <Link 
              to={`/${category.toLowerCase().replace(' ', '-')}`}
              className="flex items-center text-blue-600 hover:text-blue-700 transition-colors"
            >
              See more
              <ChevronRight className="w-4 h-4 ml-1" />
            </Link>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {articles.map((article) => (
              <Link 
                key={article.id}
                to={`/article/${article.slug}`}
                className="bg-white rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-all group"
              >
                <div className="aspect-w-16 aspect-h-9 overflow-hidden">
                  {article.images?.[0] && (
                    <img
                      src={article.images[0]}
                      alt={article.title}
                      className="w-full h-48 object-cover transition-transform duration-300 group-hover:scale-105"
                    />
                  )}
                </div>
                <div className="p-6">
                  <h3 className="text-xl font-bold mb-2 group-hover:text-blue-600 transition-colors">
                    {article.title}
                  </h3>
                  <p className="text-gray-600 mb-4 line-clamp-2">{article.description}</p>
                  <div className="flex items-center text-sm text-gray-500">
                    <span>{article.author}</span>
                    <span className="mx-2">•</span>
                    <span>{article.read_time} read</span>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}