import React, { useState, useEffect } from 'react';
import { X, AlertCircle, Loader2, Link as LinkIcon } from 'lucide-react';
import RichTextEditor from '../../../components/RichTextEditor';
import type { Article } from '../../../types';

interface ArticleFormProps {
  mode: 'create' | 'edit';
  articleData?: Article;
  onSubmit: (data: Partial<Article>, mode: 'create' | 'edit') => Promise<void>;
  onCancel: () => void;
}

export default function ArticleForm({ mode, articleData, onSubmit, onCancel }: ArticleFormProps) {
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('AI News');
  const [author, setAuthor] = useState('');
  const [description, setDescription] = useState('');
  const [content, setContent] = useState('');
  const [images, setImages] = useState<string[]>([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    if (mode === 'edit' && articleData) {
      setTitle(articleData.title);
      setCategory(articleData.category);
      setAuthor(typeof articleData.author === 'string' ? articleData.author : articleData.author.name);
      setDescription(articleData.description || '');
      setContent(articleData.content);
      setImages(articleData.images || []);
    }
  }, [mode, articleData]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      if (!title.trim()) throw new Error('Title is required');
      if (!content.trim()) throw new Error('Content is required');
      if (images.length === 0) throw new Error('At least one image is required');

      const wordCount = description.split(/\s+/).filter(Boolean).length;
      if (wordCount > 100) throw new Error('Description should not exceed 100 words');

      const formData: Partial<Article> = {
        title: title.trim(),
        category,
        author: {
          id: 'admin',
          name: author.trim(),
        },
        description: description.trim(),
        content: content.trim(),
        images,
        read_time: `${Math.ceil(content.split(/\s+/).length / 200)} min`,
      };

      await onSubmit(formData, mode);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to save article');
      setIsLoading(false);
    }
  };

  const handleImageRemove = (index: number) => {
    setImages(images.filter((_, i) => i !== index));
  };

  const handleAddImageUrl = () => {
    if (!imageUrl.trim()) {
      setError('Please enter a valid image URL');
      return;
    }

    if (images.length >= 3) {
      setError('Maximum 3 images allowed');
      return;
    }

    try {
      new URL(imageUrl);
      setImages([...images, imageUrl.trim()]);
      setImageUrl('');
      setError('');
    } catch {
      setError('Please enter a valid URL');
    }
  };

  const getWordCount = (text: string) => {
    return text.split(/\s+/).filter(Boolean).length;
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-900">
          {mode === 'create' ? 'Create Article' : 'Edit Article'}
        </h2>
        <button
          onClick={onCancel}
          className="text-gray-600 hover:text-gray-900"
          disabled={isLoading}
        >
          <X className="w-6 h-6" />
        </button>
      </div>

      {error && (
        <div className="mb-6 bg-red-50 border-l-4 border-red-500 p-4 rounded-r">
          <div className="flex items-center">
            <AlertCircle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-sm text-red-700">{error}</p>
          </div>
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label htmlFor="title" className="block text-sm font-medium text-gray-700">
            Title
          </label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            disabled={isLoading}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
          />
        </div>

        <div>
          <label htmlFor="category" className="block text-sm font-medium text-gray-700">
            Category
          </label>
          <select
            id="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            disabled={isLoading}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <option>AI News</option>
            <option>AI Money</option>
            <option>AI Tools</option>
            <option>AI Blockchain</option>
            <option>AI Course</option>
          </select>
        </div>

        <div>
          <label htmlFor="author" className="block text-sm font-medium text-gray-700">
            Author
          </label>
          <input
            type="text"
            id="author"
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
            required
            disabled={isLoading}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
          />
        </div>

        <div>
          <div className="flex justify-between items-center mb-1">
            <label htmlFor="description" className="block text-sm font-medium text-gray-700">
              Description (max 100 words)
            </label>
            <span className="text-sm text-gray-500">
              {getWordCount(description)}/100 words
            </span>
          </div>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows={3}
            required
            disabled={isLoading}
            className={`mt-1 block w-full px-3 py-2 border rounded-lg shadow-sm focus:ring-2 focus:border-transparent disabled:opacity-50 disabled:cursor-not-allowed ${
              getWordCount(description) > 100 
                ? 'border-red-300 focus:ring-red-500' 
                : 'border-gray-300 focus:ring-blue-500'
            }`}
          />
          {getWordCount(description) > 100 && (
            <p className="mt-1 text-sm text-red-600">
              Description exceeds 100 words limit
            </p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Content
          </label>
          <RichTextEditor
            value={content}
            onChange={setContent}
            disabled={isLoading}
          />
        </div>

        <div>
          <div className="flex justify-between items-center mb-2">
            <label className="block text-sm font-medium text-gray-700">
              Images (max 3)
            </label>
            <span className="text-sm text-gray-500">
              {images.length}/3 images added
            </span>
          </div>

          <div className="mb-4 flex space-x-2">
            <input
              type="url"
              value={imageUrl}
              onChange={(e) => setImageUrl(e.target.value)}
              placeholder="Enter image URL (e.g., https://images.unsplash.com/...)"
              className="flex-1 px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
              disabled={images.length >= 3 || isLoading}
            />
            <button
              type="button"
              onClick={handleAddImageUrl}
              disabled={!imageUrl || images.length >= 3 || isLoading}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed flex items-center"
            >
              <LinkIcon className="w-4 h-4 mr-2" />
              Add Image
            </button>
          </div>

          <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
            {images.map((url, index) => (
              <div key={index} className="relative group">
                <img
                  src={url}
                  alt={`Article image ${index + 1}`}
                  className="h-32 w-full object-cover rounded-lg"
                />
                <button
                  type="button"
                  onClick={() => handleImageRemove(index)}
                  disabled={isLoading}
                  className="absolute top-2 right-2 p-1 bg-red-600 text-white rounded-full hover:bg-red-700 disabled:opacity-50 disabled:cursor-not-allowed opacity-0 group-hover:opacity-100 transition-opacity"
                >
                  <X className="w-4 h-4" />
                </button>
              </div>
            ))}
          </div>
        </div>

        <div className="flex justify-end space-x-4">
          <button
            type="button"
            onClick={onCancel}
            disabled={isLoading}
            className="px-4 py-2 border border-gray-300 rounded-lg shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={isLoading}
            className="px-4 py-2 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isLoading ? (
              <div className="flex items-center">
                <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                {mode === 'create' ? 'Creating...' : 'Updating...'}
              </div>
            ) : (
              mode === 'create' ? 'Create Article' : 'Update Article'
            )}
          </button>
        </div>
      </form>
    </div>
  );
}